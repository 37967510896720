<template>
    <v-dialog v-model="dialog" persistent max-width="60%" scrollable>
    <!-- <app-loader v-model="isLoading"></app-loader> -->
      <template v-slot:activator="{ on }">
        <app-add-button v-bind:module="'company'"></app-add-button>
      </template>
          <v-form
            ref="form"
            v-model="isvalid"
            lazy-validation
          >
        <v-card tile>
          <v-toolbar
            flat
            dark
            color="primary"
          >
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn
              icon
              dark
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>

        <v-card-text>
            <v-container grid-list-md>
            <v-layout wrap>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.company_code"
                    :counter="4"
                    :rules="company_codeRules"
                    label="Company Code"
                    required
                    maxlength=4
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.company_name"
                    :counter="50"
                    :rules="company_nameRules"
                    label="Company Name"
                    required
                    maxlength=50
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-select
                    v-model="editedItem.company_status"
                    :items="companyStatus"
                    :rules="[v => !!v || 'Please select Status']"
                    label="Status"
                    data-vv-name="companyStatus"
                    required
                ></v-select>
                </v-flex>
            </v-layout>
            </v-container>
        </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue" dark @click="save">Save</v-btn>
              <v-btn color="red" text @click="close">Cancel</v-btn>
            </v-card-actions>

          </v-card>
          </v-form>
        </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAddButtonVue from '../../../partials/AppAddButton.vue';

export default {
	components:{
		'app-add-button': AppAddButtonVue
	},
	data: () => ({
		isvalid: false,
		company_codeRules: [
			v => !!v || 'Company Code is required',
			v => (v && v.length <= 4) || 'Company Code must be 4 characters or less.',
		],
		company_nameRules: [
			v => !!v || 'Company Name is required',
			v => (v && v.length <= 50) || 'Company Name must be 50 characters or less',
		],
	}),
	computed: {
		...mapGetters({
			companies: 'company/companies',
			companyStatus: 'company/companyStatus',
			editedItem: 'company/editedItem',
			defaultItem: 'company/defaultItem',
			dialog: 'company/dialog',
			editedIndex: 'company/editedIndex',
			formTitle: 'company/formTitle',
			isLoading: 'company/isLoading',
			valid: 'company/valid',
			currUser: 'auth/currUser'
		})
	}, 
	methods: {
		close () {
			setTimeout(() => {
				this.$refs.form.reset();
				this.$refs.form.resetValidation();
				this.$store.dispatch('company/setValid',true);
				this.$store.dispatch('company/setDialog',false);
				this.isvalid = false;
			}, 2000);
		},
		async save () {
			this.$store.dispatch('company/setValid',this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
        
			if(this.valid){
				this.editedItem.edit_by = this.currUser.id;
				this.$store.dispatch('company/setisLoading',true);
				if (this.editedIndex > -1) {
					await this.$store.dispatch('company/updateCompany',this.editedItem);
				} else {
					await this.$store.dispatch('company/saveCompany',this.editedItem);
				}
				setTimeout(() => {
					this.close();
				}, 3000);
			}
		}
	}
};
</script>