<template>
	<div>
		<v-data-table
			:headers="headers"
			:items="companies"
			sort-by="company_code"
			class="elevation-1"
		dense
		:search="search"
		>
			<template v-slot:top>
					<v-toolbar flat color="white">
						<v-toolbar-title>Companies</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
						<vue-excel-xlsx
							:data="companies"
							:columns="columnHeaders"
							:file-name="module_name"
							>
							<v-btn icon alt class="mt-2" :color="companies.length=== 0 ? 'gray' : 'primary'" :disabled="companies.length===0"><v-icon>mdi-arrow-down-bold-circle</v-icon> </v-btn>
						</vue-excel-xlsx>
					</v-toolbar>
			</template>
			<template v-slot:item.action="{ item }">
					<v-icon color="primary" small class="mr-2" @click="editItem(item)">edit</v-icon>
					<v-icon color="red" small @click="deleteItem(item)">delete</v-icon>
			</template>
		</v-data-table>
		<create-company></create-company>
	</div>
</template>

<script>
import createCompany from './createCompany.vue';
import { mapGetters } from 'vuex';

export default {
	components: {
		'create-company': createCompany
	},
	data() {
		return {
			search: '',
		};
	},
	computed: {
		...mapGetters({
			companies: 'company/companies',
			headers: 'company/headers',
			columnHeaders: 'company/columnHeaders',
			editedItem: 'company/editedItem',
			defaultItem: 'company/defaultItem',
			dialog: 'company/dialog',
			editedIndex: 'company/editedIndex',
			isLoading: 'company/isLoading',
			valid: 'company/valid'
		}),
	},
	mounted () {
		this.$store.dispatch('company/getCompanies');
	},
	methods: {
		async editItem (item) {
			this.$store.dispatch('company/setEditedIndex',this.companies.indexOf(item));
			//this.$store.dispatch('company/setEditedItems',Object.assign({}, item));
			await this.$store.dispatch('company/getDetails', item.id);
			this.$store.dispatch('company/setDialog',true);
			this.$store.dispatch('company/setValid',true);
			this.$store.dispatch('company/setformTitle','Edit Entry');
		},
		deleteItem (item) {
			this.$swal.fire({
				title: 'Delete ' + item.company_name + '(' + item.company_code + ')' + '?',
				text: 'You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('company/setEditedIndex', this.companies.indexOf(item));
					this.$store.dispatch('company/deleteCompany',item);
				}
			});
		}
	}
};
</script>