<template>
	<div class="home">
		<table-company></table-company>
	</div>
</template>

<script>
import tableCompany from '@/components/pages/maint/company/tableCompany.vue';

export default {
	components: {
		'table-company': tableCompany
	}
};
</script>

<style>

</style>
